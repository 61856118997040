import React from "react"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { checkIsAllsports, checkIsAllsportsData } from "../../domain_utils"

import "./Logo.scss"

const Logo = ({ type, baseUrl, country }) => {
  const { t, i18n } = useTranslation()
  const isAllsports = checkIsAllsports(country)
  const isWhite = type === "white"

  const brandType = checkIsAllsportsData(country)
  const brand = brandType.brand

  return (
    <a
      href={baseUrl ? baseUrl : "/"}
      className={classNames("logo", type)}
    >
      {isAllsports && !isWhite && (
        <StaticImage
          src="./images/logo.svg"
          alt="logo"
          placeholder="blurred"
          layout="fixed"
          width={32}
          height={34}
        />
      )}
      {!isAllsports && !isWhite && (
        <StaticImage
          src="./images/logo_sportbenefit.svg"
          alt="logo large"
          placeholder="blurred"
          layout="fixed"
        />
      )}
      {isAllsports && isWhite && (
        <StaticImage
          src="./images/logo-white.svg"
          alt="logo"
          placeholder="blurred"
          layout="fixed"
          width={32}
          height={34}
        />
      )}
      {!isAllsports && isWhite && (
        <StaticImage
          src="./images/logo_sportbenefit-white.svg"
          alt="logo large"
          placeholder="blurred"
          layout="fixed"
        />
      )}
      {isAllsports && <span>{t("head.title", { brand })}</span>}
    </a>
  )
}

export default Logo
